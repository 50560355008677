.calendar-card {
  height: 85px;
  width: 75px;
  display: flex;
  padding-top: 5px;
  border: 3px solid var(--Primary-6, #1890ff);
  background: var(--Primary-4, #69c0ff);
}

.calendar-month {
  width: 100%;
  color: var(--Neutral-1, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.calendar-year {
  width: 100%;
  height: 50px;
  border-top: 3px solid var(--Primary-6, #1890ff);
  background: var(--Neutral-1, #fff);
  color: var(--Primary-4, #69c0ff);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.date-picker-row {
  background: var(--blue-3, #c9daf8);
  width: 100%;
  padding: 12px 20px;
  align-items: center;
  justify-content: space-between;
}

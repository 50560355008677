.editIcon {
  color: rgba(0, 0, 0, 0.85);
}

.editIcon:hover {
  color: skyblue;
  transition: 0.3s all ease-in;
}

.archiveIcon:hover {
  color: purple;
  transition: 0.1s all ease-in;
}

.customDatePicker {
  visibility: hidden;
  height: 0;
  padding: 0;
  width: 0;
  left: 17%;
  top: 25%;
  position: absolute;
}

.method-search-mode-options {
  color: rgba(108, 108, 108, 0.697);
  font-style: italic;
  font-size: 11px;
  .ant-select-arrow {
    display: none;
  }
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  padding-right: 0px;
}

.option-select {
  width: 125%;
}

.project-description-title .ant-descriptions-item-label {
  font-family: Roboto, sans-serif;
  color: #656565;
  font-size: 10px;
}

.filter-tag {
  padding: 5px 10px;
  font-size: small;
  border-radius: 15px;
  border-color: #40a9ff;
}

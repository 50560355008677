.person-card-header {
  font-weight: bold;
  margin-top: 5px;
}

.person-card-content {
  margin-bottom: 1px;
}

.person-card-default {
  background-color: #ffffff;
}

.person-card-odd {
  background-color: #fcfcfc;
}

.person-card-even {
  background-color: #f7f7f7;
}

.person-card-odd:hover {
  background-color: #e3e3e3;
}

.person-card-even:hover {
  background-color: #e3e3e3;
}

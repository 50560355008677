.ant-form-item [disabled] {
  color: #888;
}

.ant-select-disabled .ant-select-selection-item {
  color: #888;
}
.isBold.ant-select-disabled .ant-select-selection-item {
  color: #888;
  font-weight: bolder;
}

.ant-picker-input > input[disabled] {
  color: #888;
}

.ant-checkbox-disabled + span {
  color: #888;
}

.ant-radio-disabled + span {
  color: #888;
}

.danger-link {
  color: #ff4d4f;
}

.target-dropdown .anticon-ellipsis {
  margin: 0;
}

.target-tabs {
  background-color: #fafcff;
  width: 100%;
}

.target-tabs .ant-tabs-tab-btn {
  width: 100%;
}

.target-tabs div.ant-tabs-tab-active {
  background-color: #e5e9f1;
}

.empty-results-icon {
  color: #b7b7b7 !important;
  font-size: 34px !important;
  padding: 15px;
  border-radius: 50%;
  background-color: #e3e6e8;
}

.empty-results-button {
  padding: 0.2em 0.6em;
  border: solid lightgrey 1px;
  border-radius: 2px;
}

.audience-segment-panel {
  min-height: 64px;
  padding: 5px !important;
  margin: 16px 20px;
  background: #f7f7f7;
  /* border: 1px solid; */
  border-radius: 2px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

.segment-loading-skeleton {
  align-items: center;
  width: 100%;
  padding: 20px;
}

.full-width-loading-skeleton .ant-space-item,
.ant-skeleton,
.ant-skeleton-input {
  width: 100%;
}

.breakdown-table .ant-table-cell {
  font-size: 14px;
  text-align: left;
}

.all-targets-breakdown-tabs .ant-tabs-tab .ant-tabs-tab-active {
  background-color: #fafcff;
  border-bottom-color: #fafcff;
}

.secondary-color {
  color: #4473b1;
}

.table-row-light {
  background-color: #fafcff;
}

.table-row-highlighted {
  background-color: #fff498fc;
  /* font-weight: bold; */
}

.left-align-title .ant-form-item-label {
  text-align: left;
  padding-left: 50px;
}

.landing-page-content {
  padding: 20px 80px;
  /* padding: 0 !important; */
  border: 10px;
}

.project-page-content {
  padding: 0 !important;
  display: flex;
  align-content: stretch;
}
